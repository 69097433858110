import { graphql, useStaticQuery } from 'gatsby';

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

export const useGeoZonesData = () => {
  const { allGeoZonesJson } = useStaticQuery(
    graphql`
      query getAllGeoZones {
        allGeoZonesJson(sort: { fields: [region, name], order: ASC }) {
          edges {
            node {
              id
              name
              region
              slug
            }
          }
        }
      }
    `,
  );

  return allGeoZonesJson.edges.map((value) => value.node);
};
