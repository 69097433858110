import React from 'react';
import alacazaIcon from '../../assets/img/alacaza-icon-white@2x.png';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { partners } from '../Partners/PartnersList';
import { useGeoZonesData } from '../Hooks/useGeoZonesData';

const applyUrl = 'https://jobs.zenploy.io/alacaza/jobs';

const MainFooter = () => {
  const geoZones = useGeoZonesData();

  return (
    <footer className="footer mt-10 pt-4 pb-10 pl-5 pr-5">
      <div className="container">
        <div className="col-12 mt-3 mb-6">
          <div className="text-align-center font-size-lg">
            Vous souhaitez nous contacter
            <br />
            ou rejoindre notre équipe ?
          </div>
          <div className="mt-5 text-align-center">
            <Link to={'/contact/'} className="btn mr-3">
              Contactez-nous
            </Link>
            <Link to={applyUrl} className="btn">
              Rejoignez-nous
            </Link>
          </div>
        </div>

        <div className="footer-content pt-6 row">
          <div className="col-3 col-12-sm text-align-center-md flex justify-content-center align-items-start">
            <img src={alacazaIcon} alt="Alacaza" className="logo" />
          </div>
          <div className="col-9 font-size-sm">
            <ul className="flex justify-content-center wrap">
              <li className="size-100-md pr-10 pb-5 pt-5 mr-25 mr-0-md">
                <ul>
                  <li>
                    <Link to="/">Résidents</Link>
                  </li>
                  <li>
                    <Link to="/professionnels/">Professionnels</Link>

                    <ul>
                      {partners.map((value, index) => (
                        <li key={'main-footer-' + value.id}>
                          <Link
                            to={`/professionnels/${value.id}/`}
                            className="font-size-md"
                          >
                            {value.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <Link to="/alacaza/">À propos</Link>
                  </li>
                  <li>
                    <Link to="/contact/">Nous contacter</Link>
                  </li>
                  <li>
                    <Link to={applyUrl}>Nous rejoindre</Link>
                  </li>
                </ul>
              </li>
              <li className="size-100-md pr-10 pb-5 pt-5 mr-25 mr-0-md">
                <ul>
                  <li>
                    <Link to="/inscription/">S'inscrire</Link>
                  </li>
                  <li>
                    <OutboundLink
                      href={process.env.GATSBY_LOGIN_URL}
                      eventAction="click"
                      eventCategory="login"
                      eventLabel="main footer"
                    >
                      Se connecter
                    </OutboundLink>
                  </li>
                </ul>
              </li>
              <li className="size-100-md pr-10 pt-5">
                <ul>
                  <li className="li-title mb-2">
                    <strong>Votre quartier</strong>
                  </li>
                  {geoZones.map((zone, index) => (
                    <li key={'main-footer-geo-zones-' + index}>
                      <Link to={`/quartiers${zone.slug}/`}>
                        {zone.region} - {zone.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-12 secondary-links">
            <ul className="font-size-xs mt-10-md text-align-right text-align-center-md">
              <li className="pl-3">
                <Link to="/cgu/">Conditions générales d'utilisation</Link>
              </li>
              <li className="pl-3">
                <Link to="/politique-de-confidentialite/">
                  Politique de confidentialité
                </Link>
              </li>
              <li className="pl-3">
                <Link to="/politique-cookies/">
                  Politique relative aux cookies
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
