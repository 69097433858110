import React from 'react';
import { Link } from 'gatsby';
import { MainHeader } from '../Header';
import ErrorBoundary from '../Error/ErrorBoundary';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import CookieConsent from '../CookieConsent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/main.scss';
import { MainFooter } from '../Footer';
import ogImg from '../../assets/img/illustrations/hello.jpg';
import useSiteMetaData from '../Hooks/useSiteMetaData';

const MainLayout = ({
  siteTitle,
  siteDescription,
  fromMobileApp = false,
  children,
}) => {
  const metaTitle = siteTitle || 'Rejoignez-vos voisins sur Alacaza';
  const metaDescription =
    siteDescription ||
    'Découvrez la première application dédiée à la vie de votre résidence et de votre quartier';

  const { siteUrl } = useSiteMetaData();
  const location = useLocation();

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />

        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:image"
          content={`${process.env.GATSBY_API_URL}/${ogImg}`}
        />

        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        <meta
          property="twitter:image"
          content={`${process.env.GATSBY_API_URL}/${ogImg}`}
        />
      </Helmet>

      <div className="main-wrapper">
        {!fromMobileApp && <MainHeader fromMobileApp={fromMobileApp} />}

        <main className="content ml-auto mr-auto">{children}</main>

        {!fromMobileApp && <MainFooter />}
        <ToastContainer />

        <CookieConsent
          location="bottom"
          buttonText="J'accepte"
          style={{ background: `#2b373b`, opacity: `0.8`, fontSize: `11px` }}
          buttonStyle={{
            background: `#FBD241`,
            color: `#271e42`,
          }}
          cookieSecurity
          expires={150}
          cookieName="gatsby-gdpr-google-analytics"
          onAccept={() => {
            initializeAndTrack(location);
          }}
        >
          Veuillez noter que nous utilisons des cookies afin de personnaliser le
          contenu, d'offrir des fonctionnalités relatives aux médias sociaux et
          d'analyser notre trafic. Ces données peuvent être partagées avec nos
          partenaires de médias sociaux, de publicité et d'analyse, qui peuvent
          combiner celles-ci avec d'autres informations que vous leur avez
          fournies ou qu'ils ont collectées lors de votre utilisation de leurs
          services.
          <Link
            to="/politique-cookies"
            style={{
              textDecoration: 'underline',
              marginLeft: '8px',
            }}
          >
            Voir la politique d'Alacaza relative aux cookies.
          </Link>
        </CookieConsent>
      </div>
    </ErrorBoundary>
  );
};

export default MainLayout;
