import React, { useState } from 'react';
import AlacazaLogo from '../../assets/img/alacaza.svg';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { partners } from '../Partners/PartnersList';

const MainHeader = ({ siteTitle, hideHeader = false, children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="header t-0 sticky ml-auto mr-auto pl-4">
      <div className="container-navbar container ml-auto mr-auto ml-0-md mr-0-md">
        <h1 className="logo">
          <Link to="/" onClick={(e) => setIsMenuOpen(false)}>
            <img src={AlacazaLogo} alt="Alacaza" />
          </Link>
        </h1>

        <nav
          id="main-menu"
          className={
            'main-menu pt-5-md font-size-lg-md ' +
            (isMenuOpen ? 'open' : 'closed')
          }
          aria-label="Main menu"
        >
          <ul className="primary-nav pl-6-md">
            <li>
              <Link
                to="/"
                activeClassName="active"
                onClick={(e) => setIsMenuOpen(false)}
              >
                <span>Résidents</span>
              </Link>
            </li>
            <li>
              <Link
                to="/professionnels/"
                partiallyActive={true}
                activeClassName="active"
                onClick={(e) => setIsMenuOpen(false)}
              >
                <span>Professionnels</span>
              </Link>

              <ul>
                <li className="parent-link">
                  <Link
                    to="/professionnels/"
                    activeClassName="active"
                    onClick={(e) => setIsMenuOpen(false)}
                  >
                    Découvrez nos offres
                  </Link>
                </li>
                {partners.map((partner, index) => (
                  <li key={'menu-partner-' + index}>
                    <Link
                      to={`/professionnels/${partner.id}/`}
                      activeClassName="active"
                      onClick={(e) => setIsMenuOpen(false)}
                    >
                      {partner.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <Link
                to="/alacaza/"
                partiallyActive={true}
                activeClassName="active"
                onClick={(e) => setIsMenuOpen(false)}
              >
                <span>À propos d'Alacaza</span>
              </Link>
            </li>
            <li>
              <Link
                to="/contact/"
                partiallyActive={true}
                activeClassName="active"
                onClick={(e) => setIsMenuOpen(false)}
              >
                <span>Nous contacter</span>
              </Link>
            </li>
          </ul>

          <ul className="secondary-nav pr-4 pl-6-md pr-6-md">
            <li>
              <Link
                to="/inscription/"
                className="orange b-radius-5 text-decoration-none"
              >
                S'inscrire
              </Link>
            </li>
            <li className="ml-2 ml-0-md">
              <OutboundLink
                href={process.env.GATSBY_LOGIN_URL}
                className="b-radius-5 text-decoration-none"
                eventAction="click"
                eventCategory="login"
                eventLabel="main header"
              >
                Se connecter
              </OutboundLink>
            </li>
          </ul>
        </nav>

        <button
          className="menu-toggle"
          onClick={(e) => setIsMenuOpen(!isMenuOpen)}
        >
          <FontAwesomeIcon
            icon={isMenuOpen ? faTimes : faBars}
            aria-hidden="true"
            width={0}
          />
        </button>
      </div>
    </header>
  );
};

export default MainHeader;
