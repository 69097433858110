import React from 'react';
import AlacazaLogo from '../../assets/img/alacaza.svg';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const SignUpHeader = () => {
  return (
    <header className="header t-0 sticky ml-auto mr-auto pl-2 pl-4-md mb-15">
      <div className="container-navbar container ml-auto mr-auto ml-0-md mr-0-md">
        <h1 className="logo">
          <Link to="/" className="ml-2">
            <img src={AlacazaLogo} alt="Alacaza" />
          </Link>
        </h1>
        <nav className="nav float-right pr-2 pt-2 pt-0-md pr-0-md">
          <ul>
            <li>
              <OutboundLink
                href={process.env.GATSBY_LOGIN_URL}
                className="b-radius-5 text-decoration-none"
                eventAction="click"
                eventCategory="login"
                eventLabel="signup header"
              >
                Se connecter
              </OutboundLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default SignUpHeader;
