import React from 'react';
import { useSelector } from 'react-redux';

const SignUpFooter = () => {
  const preContent = useSelector((state) => state.footer.preContent);

  return (
    <footer
      className={
        'footer pt-5 pl-2-md pr-2-md ' +
        (preContent && preContent !== null ? ' with-precontent' : '')
      }
    >
      {preContent && preContent !== null ? preContent : ''}

      <div className="footer-content container row mt-2 pb-2 text-align-left font-size-md font-size-xs-sm">
        <div className="col-12 text-align-right">
          <ul className="ml-4 mr-4">
            {/*<li><a href="/faq">Faq</a></li>*/}
            {/*<li className="text-color-grey-dark">|</li>*/}
            <li>
              <a href="/cgu/">Conditions générales d'utilisation</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default SignUpFooter;
