import React from 'react';
import { Link } from 'gatsby';

import iconSyndic from '../../assets/img/partners/syndic@2x.png';
import iconBailleur from '../../assets/img/partners/bailleur@2x.png';
import iconCollectivite from '../../assets/img/partners/collectivit@2x.png';
import iconPromoteur from '../../assets/img/partners/promoteur@2x.png';
import iconAutres from '../../assets/img/partners/partner-others.png';

export const partners = [
  {
    id: 'syndics',
    icon: iconSyndic,
    title: 'Syndics de copropriétés',
  },
  {
    id: 'bailleurs',
    icon: iconBailleur,
    title: 'Bailleurs sociaux ',
  },
  {
    id: 'collectivites',
    icon: iconCollectivite,
    title: 'Collectivités territoriales',
  },
  {
    id: 'promoteurs',
    icon: iconPromoteur,
    title: 'Promoteurs immobiliers',
  },
  {
    id: 'autres',
    icon: iconAutres,
    title: 'Autres',
  },
];

const PartnersList = ({ selectedPartner }) => {
  if (!selectedPartner) {
    return (
      <section className="container mt-10 mt-5-md section-partners-list">
        <div className="row">
          <div className="col-12">
            <div className="roboto bold font-size-xl text-align-center">
              Qui êtes-vous ?
            </div>
          </div>

          <div className="col-12">
            <div className="partners-list mt-5">
              <ul className="text-align-center">
                {partners.map((partner, index) => (
                  <li
                    key={'partner-' + index}
                    className={
                      'mb-5 ml-2 mr-2 ml-1-md mr-1-md ' +
                      (selectedPartner === partner.id ? 'selected' : '')
                    }
                  >
                    <Link
                      to={`/professionnels/${partner.id}/`}
                      className="block height-100"
                    >
                      <div className="partner-icon text-align-center pt-5 pt-3-md pl-2 pr-2">
                        <img src={partner.icon} alt={partner.title} />
                      </div>
                      <div className="bold roboto text-align-center pt-3 pt-2-md pl-2 pr-2 font-size-sm-md text-decoration-none">
                        {partner.title}
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="container mt-10 mt-5-md section-partners-list">
      <div className="col-12">
        <div className={'partners-list mt-5 small-partners-list'}>
          <ul className="text-align-center">
            {partners.map((partner, index) => (
              <li
                key={'partner-' + index}
                className={
                  'mb-5 ml-2 mr-2 ml-1-md mr-1-md ' +
                  (selectedPartner === partner.id ? 'selected' : '')
                }
              >
                <Link
                  to={`/professionnels/${partner.id}/`}
                  className="block height-100"
                >
                  <div className="partner-icon text-align-center pt-3 pl-2 pr-2">
                    <img src={partner.icon} alt={partner.title} />
                  </div>
                  <div className="bold roboto text-align-center pt-2 pl-2 pr-2 font-size-sm text-decoration-none">
                    {partner.title}
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12">
          <h1 className="roboto text-align-center pl-2-md pr-2-md">
            {
              partners
                .filter((p) => p.id === selectedPartner)
                .map((p) => p.title)[0]
            }
          </h1>
        </div>
      </div>
    </section>
  );
};

export default PartnersList;
