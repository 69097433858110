import React from 'react';
import PropTypes from 'prop-types';
import { SignUpHeader } from '../Header';
import { SignUpFooter } from '../Footer';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from '../Error/ErrorBoundary';
import '../../styles/signup.scss';
import useSiteMetaData from '../Hooks/useSiteMetaData';
import ogImg from '../../assets/img/illustrations/hello.jpg';

const SignUpLayout = ({
  siteTitle,
  siteDescription,
  fromMobileApp,
  children,
}) => {
  const metaTitle =
    !!siteTitle && siteTitle.length > 0
      ? siteTitle
      : 'Inscrivez-vous sur Alacaza';

  const metaDescription =
    !!siteDescription && siteDescription.length > 0
      ? siteDescription
      : 'Inscrivez-vous sur Alacaza et rejoignez-vos voisins sur la première application dédiée à la vie de votre résidence et de votre quartier';

  const { siteUrl } = useSiteMetaData();

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />

        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:image"
          content={`${process.env.GATSBY_API_URL}/${ogImg}`}
        />
        <meta property="og:url" content={siteUrl} />

        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        <meta
          property="twitter:image"
          content={`${process.env.GATSBY_API_URL}/${ogImg}`}
        />
      </Helmet>

      <div className="signup-wrapper">
        {!fromMobileApp && <SignUpHeader />}

        <div className="content ml-auto mr-auto">{children}</div>

        <SignUpFooter />
        <ToastContainer />
      </div>
    </ErrorBoundary>
  );
};

SignUpLayout.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  fromMobileApp: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export default SignUpLayout;
